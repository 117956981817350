import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Editor, Toolbar, Validators } from 'ngx-editor';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OpenPositionService } from '../../services/open-position.service';
import { Observable, takeUntil, tap } from 'rxjs';
import { CostCenterService } from 'src/app/admin-portal/modules/cost-center/services/cost-center.service';
import { ProjectService } from 'src/app/admin-portal/modules/project/services/project.service';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { whitespaceValidator } from 'src/app/utils/whitespace-validation.utils';

interface BaseData {
  role: any;
  experienceRange: any;
  jobDescription: any;
  noOfResource: any;
  startDate: any;
  cost_centerID: any;
}

@Component({
  selector: 'app-job-post',
  templateUrl: './job-post.component.html',
})
export class JobPostComponent implements OnInit, OnDestroy {
  id!: string;
  positionForm!: FormGroup;
  editor: any = Editor;
  toolbar: Toolbar = [['bold', 'italic', 'underline'], ['bullet_list']];
  costList: any[] = [];
  experienceRange: any[] = [];
  projectList: any[] = [];
  isEditMode: boolean = false;
  isButtonDisabled: boolean = true;
  isNumericInputValid: boolean = true;
  accountID: string = '';

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private openPositionService: OpenPositionService,
    private costCenterService: CostCenterService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private globalService: GlobalService
  ) {}

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.accountID = user?.associatedAccount?.accountID;

    this.id = this.route.snapshot.params['id'];
    this.editor = new Editor();
    this.initializeForm();
    this.loadCostCenters().subscribe();
    this.loadExperienceRange().subscribe();
    this.loadProjects().subscribe();
    this.subscribeToRouteParams();

    this.positionForm.valueChanges.subscribe(() => {
      this.checkFormChanges();
    });
    this.isButtonDisabled = true;
  }

  checkFormChanges(): void {
    this.isButtonDisabled =
      !this.positionForm.dirty || this.positionForm.invalid;
  }

  subscribeToRouteParams(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.isEditMode = true;
        this.fetchJobPost(id)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: (roleDetails: any) => {
              this.patchForm(roleDetails?.data);
            },
            error: (error: any) => {
              console.error('Error fetching job post details:', error);
            },
          });
      } else {
        this.isEditMode = false;
      }
    });
  }

  fetchJobPost(id: string): Observable<any> {
    return this.openPositionService.getOpenPositionById(id);
  }

  patchForm(positionDetails: any): void {
    try {
      if (positionDetails) {
        this.positionForm.patchValue({
          role: positionDetails.role,
          projectId: positionDetails?.project?.projectId,
          cost_centerID: positionDetails.costCenter.cost_centerID,
          experienceRange: positionDetails.experienceRange,
          startDate: positionDetails.startDate,
          noOfResource: positionDetails.noOfResource,
          jobDescription: positionDetails.jobDescription,
        });
        this.positionForm.markAsPristine();
        this.checkFormChanges();
      }
    } catch (error) {
      console.error(
        'An error occurred while patching form with position details:',
        error
      );
    }
  }

  initializeForm(): void {
    this.positionForm = this.fb.group({
      role: ['', [Validators.required, whitespaceValidator()]],
      projectId: [''],
      cost_centerID: ['', Validators.required],
      experienceRange: ['', Validators.required],
      startDate: ['', Validators.required],
      noOfResource: ['', Validators.required],
      jobDescription: ['', [Validators.required, whitespaceValidator()]],
    });
  }

  loadCostCenters(): Observable<any> {
    const MAX_LIMIT = 9999;
    return this.costCenterService
      .getCostListCustomer(0, MAX_LIMIT, '', 'createdAt', 'ASC', this.accountID)
      .pipe(
        tap((response: any) => {
          this.costList = response.data.records;
        })
      );
  }

  loadExperienceRange(): Observable<any> {
    return this.openPositionService
      .getExperianceRange()
      .pipe(
        tap((response: any) => {
          this.experienceRange = response.data.records;
        })
      );
  }

  loadProjects(): Observable<any> {
    const MAX_LIMIT = 9999;
    return this.projectService
      .getProjectListCustomer(
        0,
        MAX_LIMIT,
        '',
        'createdAt',
        'ASC',
        '',
        this.accountID
      )
      .pipe(
        tap((response: any) => {
          this.projectList = response.data.records;
        })
      );
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  openPositions() {
    this.router.navigate(['customer/open-position']);
  }

  onSubmit(): void {
    if (this.positionForm.valid) {
      const {
        role,
        experienceRange,
        jobDescription,
        noOfResource,
        startDate,
        projectId,
      } = this.positionForm.value;
      const cost_centerID = this.positionForm.get('cost_centerID')?.value;

      const baseData: BaseData = {
        role,
        experienceRange,
        jobDescription,
        noOfResource,
        startDate,
        cost_centerID,
      };

      // Conditionally create the final data object
      let finalData: BaseData & { projectId?: any };
      if (projectId) {
        finalData = { ...baseData, projectId };
      } else {
        finalData = { ...baseData };
      }

      if (this.isEditMode) {
        // If in edit mode, update the job post
        const positionID = this.id;
        const updatedCoreTeamData = { ...finalData, positionID };

        this.openPositionService
          .updateOpenPosition(updatedCoreTeamData)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: () => {
              this.router.navigate(['customer/open-position']);
            },
            error: (error: any) => {
              console.error('Error updating core team:', error);
            },
          });
      } else {
        // If not in edit mode, create a new job post
        const jobDescriptionPlain = this.stripHTMLTags(
          this.positionForm.get('jobDescription')?.value
        );

        const newJobPostData = {
          ...finalData,
          jobDescription: jobDescriptionPlain,
        };

        this.openPositionService
          .createOpenPosition(newJobPostData)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: () => {
              this.router.navigate(['customer/open-position']);
            },
            error: (errorResponse: any) => {
              console.error('Error creating job post:', errorResponse);
            },
          });
      }
    } else {
      console.error('Invalid form data.');
    }
  }

  stripHTMLTags(html: string): string {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  handleNumericInputValidityChange(validity: boolean) {
    this.isNumericInputValid = validity;
  }
}
