import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { OpenPositionService } from '../../services/open-position.service';
import { debounceTime, takeUntil } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { PageEvent } from '@angular/material/paginator';
import { FilterOpenPositionSidebarComponent } from './filter-sidebar/filter-open-position-sidebar.component';

export interface PeriodicElement {
  role: string;
  projectName: string;
  costCenter: string;
  range: string;
  startDate: string;
  openings: string;
  action: string;
}

@Component({
  selector: 'app-open-position',
  templateUrl: './open-position.component.html',
})
export class CustomerOpenPositionComponent {
  openPositionList: any[] = [];
  pageNumber: number = 0;
  limit: number = 25;
  search: string = '';
  sortBy: 'jobTitle' | 'modifiedAt' = 'modifiedAt';
  orderBy: 'ASC' | 'DESC' = 'DESC';
  form!: FormGroup;
  totalRecords: number = 0;
  lastSortBy: string = '';
  accountID: string = '';
  loading: boolean = false;
  costCenterList: any[] = [];
  costCenterID: string | undefined = undefined;
  element: any;
  selectedProjects: string[] = [];
  selectedCostCenters: string[] = [];
  selectedExperienceRange: string[] = [];
  projectId: string | undefined = undefined;
  isFilterApplied: boolean = false;

  @ViewChild('filter') filterComponent!: FilterOpenPositionSidebarComponent;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private router: Router,
    private formbuilder: FormBuilder,
    private openPositionService: OpenPositionService,
    private globalService: GlobalService
  ) {}

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.accountID = user?.associatedAccount?.accountID;

    this.orderBy = 'DESC';
    this.getOpenPositionList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy
    );
    this.form = this.formbuilder.group({
      searchQuery: [''],
    });
    this.searchOpenPosition();
  }

  openFilterDrawer(): void {
    if (this.filterComponent) {
      this.filterComponent.isOpen = !this.filterComponent.isOpen;
    } else {
    }
  }

  displayedColumns: string[] = [
    'role',
    'costCenter',
    'projectName',
    'range',
    'startDate',
    'openings',
    'action',
  ];
  dataSource = new MatTableDataSource<PeriodicElement>([]);

  getOpenPositionList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC'
  ): void {
    this.loading = true;
    this.openPositionService
      .getOpenPositionListCustomer(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        undefined,
        this.accountID
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.openPositionList = data?.data?.records;
          this.totalRecords = data?.data?.count || 0;
          this.loading = false;
          this.openPositionList.sort((a, b) => {
            return (
              new Date(b.modifiedAt).getTime() -
              new Date(a.modifiedAt).getTime()
            );
          });
        },
        error: (error: any) => {
          console.error('Error in Open Position List request:', error);
          this.loading = false;
        },
      });
  }

  getOpenPositionListByCostCenter(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedProjects: string[]
  ): void {
    this.loading = true;
    const cost_centerID = selectedProjects.join('&cost_centerID=');
    this.openPositionService
      .getOpenPositionListCustomer(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        cost_centerID,
        this.accountID
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response) => {
          this.openPositionList = response.data.records;
          this.totalRecords = response.data.count || 0;
          this.loading = false;
        },
        error: (error) => {
          console.error('Error in Open Position List request:', error);
          this.loading = false;
        },
      });
  }

  getOpenPositionListByExperienceRange(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedExperienceRange: string[]
  ): void {
    this.loading = true;
    const rangeFilters = selectedExperienceRange
      .map((range) => decodeURIComponent(range))
      .join('&experienceRange=');
    this.openPositionService
      .getOpenPositionListCustomer(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        undefined,
        this.accountID,
        undefined,
        rangeFilters
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response) => {
          this.openPositionList = response.data.records;
          this.totalRecords = response.data.count || 0;
          this.loading = false;
        },
        error: (error) => {
          console.error('Error in Open Position List request:', error);
          this.loading = false;
        },
      });
  }

  getOpenPositionListByProject(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedProjects: string[]
  ): void {
    this.loading = true;
    const projectId = selectedProjects.join('&projectId=');
    this.openPositionService
      .getOpenPositionListCustomer(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        undefined,
        this.accountID,
        projectId
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response) => {
          this.openPositionList = response.data.records;
          this.totalRecords = response.data.count || 0;
          this.loading = false;
        },
        error: (error) => {
          console.error('Error in Open Position List request:', error);
          this.loading = false;
        },
      });
  }

  getOpenPositionListByCostCenterAndExperienceRange(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedCostCenters: string[],
    selectedExperienceRange: string[]
  ): void {
    this.loading = true;
    const costCenterID = selectedCostCenters.join('&cost_centerID=');
    const experienceRange = selectedExperienceRange
      .map((range) => decodeURIComponent(range))
      .join('&experienceRange=');

    this.openPositionService
      .getOpenPositionListCustomer(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        costCenterID,
        undefined,
        experienceRange
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response) => {
          this.openPositionList = response.data.records;
          this.totalRecords = response.data.count || 0;
          this.loading = false;
        },
        error: (error) => {
          console.error('Error in Open Position List request:', error);
          this.loading = false;
        },
      });
  }

  getOpenPositionListByProjectAndExperienceRange(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedProjects: string[],
    selectedExperienceRange: string[]
  ): void {
    this.loading = true;
    const projectId = selectedProjects.join('&projectId=');
    const experienceRange = selectedExperienceRange
      .map((range) => decodeURIComponent(range))
      .join('&experienceRange=');

    this.openPositionService
      .getOpenPositionListCustomer(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        undefined,
        projectId,
        experienceRange
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response) => {
          this.openPositionList = response.data.records;
          this.totalRecords = response.data.count || 0;
          this.loading = false;
        },
        error: (error) => {
          console.error('Error in Open Position List request:', error);
          this.loading = false;
        },
      });
  }

  getOpenPositionListByAllFilters(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedProjects: string[],
    selectedCostCenters: string[],
    selectedExperienceRange: string[]
  ): void {
    this.loading = true;
    const projectId = selectedProjects.join('&projectId=');
    const costCenterID = selectedCostCenters.join('&cost_centerID=');
    const experienceRange = selectedExperienceRange
      .map((range) => decodeURIComponent(range))
      .join('&experienceRange=');

    this.openPositionService
      .getOpenPositionListCustomer(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        costCenterID,
        this.accountID,
        projectId,
        experienceRange
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response) => {
          this.openPositionList = response.data.records;
          this.totalRecords = response.data.count || 0;
          this.loading = false;
        },
        error: (error) => {
          console.error('Error in Open Position List request:', error);
          this.loading = false;
        },
      });
  }

  handleFilterApplied(filters: any): void {
    this.selectedProjects = filters.selectedProjects;
    this.selectedCostCenters = filters.selectedCostCenters;
    this.selectedExperienceRange = filters.selectedExperienceRange;
    this.search = filters.search;

    if (
      this.selectedProjects.length === 0 &&
      this.selectedCostCenters.length === 0 &&
      this.selectedExperienceRange.length === 0
    ) {
      // Clear filters action
      this.getOpenPositionList(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy
      );
    } else if (
      this.selectedProjects.length > 0 &&
      this.selectedCostCenters.length === 0
    ) {
      // Filter by projects only
      this.getOpenPositionListByAllFilters(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.selectedProjects,
        this.selectedCostCenters,
        this.selectedExperienceRange
      );
    } else if (
      this.selectedCostCenters.length > 0 &&
      this.selectedExperienceRange.length === 0
    ) {
      // Filter by cost centers only
      this.getOpenPositionListByAllFilters(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.selectedProjects,
        this.selectedCostCenters,
        this.selectedExperienceRange
      );
    } else if (
      this.selectedExperienceRange.length > 0 &&
      this.selectedProjects.length === 0
    ) {
      // Filter by experience range only
      this.getOpenPositionListByAllFilters(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.selectedProjects,
        this.selectedCostCenters,
        this.selectedExperienceRange
      );
    } else if (this.selectedProjects.length > 0) {
      // Filter by projects and experience range
      this.getOpenPositionListByProject(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.selectedProjects
      );
    } else if (this.selectedCostCenters.length > 0) {
      // Filter by cost centers and experience range
      this.getOpenPositionListByCostCenter(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.selectedCostCenters
      );
    } else if (this.selectedExperienceRange.length > 0) {
      // Filter by projects, cost centers, and experience range
      this.getOpenPositionListByExperienceRange(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.selectedExperienceRange
      );
    } else {
      this.getOpenPositionList(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy
      );
    }
    this.isFilterApplied = filters && Object.keys(filters).length > 0;
  }

  onPageChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex;
    this.limit = event.pageSize;
    this.getOpenPositionList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy
    );
  }

  onOpenPositionListSort(event: Sort): void {
    const sortBy = event.active;
    const orderBy = event.direction.toUpperCase() as 'ASC' | 'DESC';
    const validOrderBy = orderBy || 'ASC';

    if (sortBy !== this.lastSortBy) {
      this.pageNumber = 0;
    }
    this.lastSortBy = sortBy;

    this.openPositionService
      .getOpenPositionList(
        this.pageNumber,
        this.limit,
        this.search,
        sortBy,
        validOrderBy,
        this.accountID
      )
      .subscribe({
        next: (data: any) => {
          this.openPositionList = data?.data?.records || [];
          this.dataSource.data = this.openPositionList;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in Open Position List request:', error);
        },
      });
  }

  createPosition() {
    this.router.navigate(['customer/open-position/create-post']);
  }

  setElement(selectedElement: any): void {
    this.element = selectedElement;
  }

  onEditPosition(positionID: string): void {
    this.router.navigate(['customer/open-position/edit-post', positionID]);
  }

  searchOpenPosition(): void {
    this.form
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        this.getOpenPositionList(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy
        );
      });
  }

  getFormattedExperienceRange(experienceRange: string): string {
    if (!experienceRange) {
      return '--';
    }

    const rangeMap: { [key: string]: string } = {
      'Lead or aboveLead or above (8+ years)': 'Lead or above (8+ years)',
      'Junior (2 years - 4 years)': 'Junior (2 - 4 years)',
      'Senior (6 years – 8 years)': 'Senior (6 - 8 years)',
      'Mid-Experience (4 years - 6 years)': 'Mid-Experience (4 - 6 years)',
      'Graduate (up to 2 year)': 'Graduate (up to 2 year)',
    };

    return rangeMap[experienceRange] || experienceRange;
  }
}
