<div class="modal-wrapper p-8">
  <div class="modal-header d-flex justify-between">
    <div>
      <div class="delete-circle delete-icon-wrapper">
        <span class="material-symbols-outlined warning-text fw-light">
          warning
        </span>
      </div>

      <h2 class="mat-subtitle-1 mt-2 text-black">
        {{ data.message }}
      </h2>
      <p class="textXS mt-2 text-black">This action Can’t be undone.</p>
    </div>
    <mat-icon class="pointer" (click)="onNoClick()">close</mat-icon>
  </div>
  <div class="modal-footer mt-2 d-flex justify-end">
    <sft-button
      [label]="'Close'"
      [color]="'warn'"
      [variant]="'flat'"
      [disableRipple]="false"
      [size]="'large'"
      (buttonClick)="onYesClick()"
    ></sft-button>
  </div>
</div>
