<div class="main-content body-bg listing-wrapper customer-resource">
  <div class="p-6">
    <div class="d-flex justify-between align-center mb-6">
      <div>
        <h4 class="mat-headline-5 strong text mb-1">Resources</h4>
        <sft-button
          [label]="
            'Cybersecurity Sentinel
            '
          "
          [color]="'primary'"
          className="grey-text-btn textXS p-0"
          [variant]="'default'"
          [disable]="false"
          [withIcon]="true"
          [icon]="'keyboard_backspace'"
          [disableRipple]="false"
          [size]="'small'"
          (buttonClick)="goBack()"
        ></sft-button>
      </div>
      <div class="d-flex align-center">
        <sft-button
          [label]="'(View job details)'"
          [className]="'font-italic mat-body-1 text-blue-button  mr-2'"
          [color]="'primary'"
          [variant]="'default'"
          [disable]="false"
          [icon]="'home'"
          [disableRipple]="false"
          [size]="'medium'"
          (buttonClick)="openViewJobDrawer()"
        ></sft-button>
        <sft-button
          *ngIf="candidateList.length > 0"
          [label]="'Close this Position'"
          [color]="'warn'"
          [variant]="'flat'"
          [icon]="'home'"
          [disableRipple]="false"
          [size]="'xl'"
          (buttonClick)="closePost()"
        ></sft-button>
      </div>
    </div>
    <app-loader *ngIf="loading"></app-loader>
    <div
      *ngIf="!loading && candidateList.length === 0"
      class="required-star"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 200px);
        font-size: 20px;
      "
    >
      No Resources found!
    </div>
    <div
      class="body-bg listing-content overflow-auto craft-listing cost-listing-wrapper"
      *ngIf="!loading && candidateList.length > 0"
    >
      <table
        mat-table
        [dataSource]="candidateList"
        class="custom-action-table"
        matSort
        (matSortChange)="onCandidateSort($event)"
      >
        <ng-container matColumnDef="name">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="name-info"
            mat-sort-header
          >
            Name
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex align-center">
              <div class="table-avatar mr-2" style="position: relative">
                <ng-container *ngIf="element.logo; else defaultLogo">
                  <img
                    src="/images/profile.png"
                    alt="profile"
                    style="border-radius: 50%"
                  />
                </ng-container>
                <ng-template #defaultLogo>
                  <div
                    class="table-avatar mr-2"
                    style="
                      background-color: #005F8E;
                      color: #fff;
                      border-radius: 50%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    {{ getDefaultPhotoText(element.cd_name) }}
                  </div>
                </ng-template>
              </div>
              <span
                class="grey-text name-info-col table-ellipse"
                title="{{ element.cd_name || '--' }}"
                >{{ element.cd_name | truncate : 10 || "--" }}</span
              >
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef class="email" mat-sort-header>
            Email Address
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="grey-text table-ellipse email-info"
            title="{{ element.cd_email }}"
          >
            {{ element.cd_email | truncateEmail : 25 }}
          </td>
        </ng-container>
        <ng-container matColumnDef="notice">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="notice-period"
            mat-sort-header="noticePeriod"
          >
            Notice Period
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="grey-text table-ellipse email-info"
            title=" {{ element.cd_notice_period || '--' }}"
          >
            {{ element.cd_notice_period || "--" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="resume">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="resume-info"
            mat-sort-header
          >
            Resume
          </th>
          <td mat-cell *matCellDef="let element" class="grey-text">
            <div class="d-flex align-center resumne-icon">
              <span
                class="material-symbols-outlined mr-2"
                (click)="downloadResume(element.cd_resume)"
                style="cursor: pointer"
              >
                download
              </span>
              <p class="text-info">{{ element.cd_resume || "--" }}</p>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="application">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="application-date"
            mat-sort-header="createdAt"
          >
            Application Date
          </th>
          <td mat-cell *matCellDef="let element" class="grey-text">
            {{ element.cd_created_at | date : "dd MMM, yyyy" : "UTC" || "--" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="status-info"
            mat-sort-header="status"
          >
            Status
          </th>
          <td mat-cell *matCellDef="let element">
            <div
              *ngIf="isStatusScreening(element.cd_status)"
              class="success-text success-light-bg w-fit px-2 table-chip"
            >
              {{ getFormattedStatus(element.cd_status) }}
            </div>

            <div
              *ngIf="isStatusSelected(element.cd_status)"
              class="warning-light-bg warning-text w-fit px-2 table-chip"
            >
              {{ getFormattedStatus(element.cd_status) }}
            </div>

            <div
              *ngIf="isStatusInterview(element.cd_status)"
              class="info-text info-light-bg w-fit px-2 table-chip"
            >
              {{ getFormattedStatus(element.cd_status) }}
            </div>

            <div
              *ngIf="isStatusRejected(element.cd_status)"
              class="error-text error-light-bg w-fit px-2 table-chip error-lighten"
            >
              {{ getFormattedStatus(element.cd_status) }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="action">Action</th>
          <td
            mat-cell
            *matCellDef="let element"
            class="text-center"
            style="cursor: pointer"
          >
            <mat-icon
              [matMenuTriggerFor]="menuEdit"
              (click)="setElement(element)"
            >
              more_vert
            </mat-icon>
          </td>
          <mat-menu #menuEdit="matMenu" class="action-info">
            <button mat-menu-item (click)="openEditDialog(element)">
              Edit
            </button>
          </mat-menu>
        </ng-container>
        <tr
          mat-header-row
          class="action-table-header-data"
          *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
          mat-row
          class="action-table-body-data"
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
      <mat-paginator
        *ngIf="candidateList.length > 0"
        class="action-table-pagination fixed bottom-0 project-listing"
        [length]="totalRecords"
        [pageSize]="limit"
        [pageIndex]="pageNumber"
        [pageSizeOptions]="[25, 50, 75, 100]"
        (page)="onPageChange($event)"
        aria-label="Select page"
      >
      </mat-paginator>
    </div>
  </div>
</div>
<app-view-job-detail #viewJobDetail [id]="id"></app-view-job-detail>
