import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDrawerMode } from '@angular/material/sidenav';
import { Observable, debounceTime, distinctUntilChanged, map, tap } from 'rxjs';
import { CostCenterService } from 'src/app/admin-portal/modules/cost-center/services/cost-center.service';
import { ProjectService } from 'src/app/admin-portal/modules/project/services/project.service';
import { OpenPositionService } from '../../../services/open-position.service';

@Component({
  selector: 'app-open-position-dialog-filter',
  templateUrl: './filter-open-position-sidebar.component.html',
})
export class FilterOpenPositionSidebarComponent {
  side!: MatDrawerMode;
  isOpen: boolean = false;
  form!: FormGroup;
  search: string = '';
  accountID: string = '';
  items = ['Cost Center', 'Experience Range', 'Project Name'];
  expandedIndex = 0;
  costCenters: any[] = [];
  experienceRange: any[] = [];
  projects: any[] = [];
  filteredProjects: any[] = [];
  totalRecords: number = 0;
  selectedProjects: string[] = [];
  selectedCostCenters: string[] = [];
  selectedExperienceRange: string[] = [];
  pageNumber: number = 0;
  limit: number = 25;
  sortBy: 'modifiedAt' = 'modifiedAt';
  orderBy: 'ASC' | 'DESC' = 'ASC';
  loading: boolean = false;

  selectedProjectsMap: { [key: string]: boolean } = {};
  selectedExperienceRangeMap: { [key: string]: boolean } = {};
  selectedCostCentersMap: { [key: string]: boolean } = {};

  @Output() filtersApplied = new EventEmitter<any>();

  constructor(
    private costCenterService: CostCenterService,
    private projectService: ProjectService,
    private formbuilder: FormBuilder,
    private openPosition: OpenPositionService
  ) {}

  ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.accountID = user?.associatedAccount?.accountID;

    this.form = this.formbuilder.group({
      searchQuery: [''],
    });

    this.loadCostCenters().subscribe();
    this.loadExpirianceRange().subscribe();
    this.loadProjects().subscribe();

    this.form
      .get('searchQuery')
      ?.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap((value) => (this.search = value)),
        map((value) => this.searchProjects(value))
      )
      .subscribe();
  }

  loadExpirianceRange(): Observable<any> {
    return this.openPosition.getExperianceRange().pipe(
      tap((response: any) => {
        this.experienceRange = response.data.records;
      })
    );
  }

  loadCostCenters(): Observable<any> {
    const MAX_LIMIT = 9999;
    return this.costCenterService
      .getCostListCustomer(0, MAX_LIMIT, '', 'createdAt', 'ASC', this.accountID)
      .pipe(
        tap((response: any) => {
          this.costCenters = response.data.records;
        })
      );
  }

  loadProjects(): Observable<any> {
    const MAX_LIMIT = 9999;
    return this.projectService
      .getProjectListCustomer(
        0,
        MAX_LIMIT,
        '',
        'createdAt',
        'ASC',
        '',
        this.accountID
      )
      .pipe(
        tap((response: any) => {
          this.projects = response.data.records;
          this.filteredProjects = this.projects;
        })
      );
  }

  hasSelectedFilters(): boolean {
    return (
      this.selectedCostCenters.length > 0 ||
      this.selectedProjects.length > 0 ||
      this.selectedExperienceRange.length > 0
    );
  }

  applyFilters(): void {
    if (this.hasSelectedFilters()) {
      this.filtersApplied.emit({
        selectedProjects: this.selectedProjects,
        selectedCostCenters: this.selectedCostCenters,
        selectedExperienceRange: this.selectedExperienceRange,
        search: this.search,
      });
      this.isOpen = false;
    }
  }

  clearFilters(): void {
    this.selectedProjects = [];
    this.selectedCostCenters = [];
    this.selectedExperienceRange = [];

    this.selectedProjectsMap = {};
    this.selectedCostCentersMap = {};
    this.selectedExperienceRangeMap = {};
    this.search = '';

    this.filtersApplied.emit({
      selectedProjects: this.selectedProjects,
      selectedCostCenters: this.selectedCostCenters,
      selectedExperienceRange: this.selectedExperienceRange,
      search: this.search,
    });
  }

  // Handlers for checkbox changes
  onCostCenterChange(event: any, center: any): void {
    if (event.checked) {
      this.selectedCostCenters.push(center.cc_cost_centerID);
    } else {
      const index = this.selectedCostCenters.indexOf(center.cc_cost_centerID);
      if (index > -1) {
        this.selectedCostCenters.splice(index, 1);
      }
    }
  }

  onExperienceRangeChange(event: any, range: string): void {
    if (event.checked) {
      this.selectedExperienceRange.push(range);
    } else {
      const index = this.selectedExperienceRange.indexOf(range);
      if (index > -1) {
        this.selectedExperienceRange.splice(index, 1);
      }
    }
  }

  onProjectChange(event: any, project: any): void {
    if (event.checked) {
      this.selectedProjects.push(project.projectId);
    } else {
      const index = this.selectedProjects.indexOf(project.projectId);
      if (index > -1) {
        this.selectedProjects.splice(index, 1);
      }
    }
  }

  toggleCostCenterCheckbox(event: any, center: any) {
    const checkbox = event.target.querySelector('mat-checkbox input');
    if (checkbox) {
      checkbox.click();
    }
  }

  toggleExperienceRangeCheckbox(event: any, range: string) {
    const checkbox = event.target.querySelector('mat-checkbox input');
    if (checkbox) {
      checkbox.click();
    }
  }

  toggleProjectCheckbox(event: any, project: any) {
    const checkbox = event.target.querySelector('mat-checkbox input');
    if (checkbox) {
      checkbox.click();
    }
  }

  searchProjects(searchValue: string) {
    if (!searchValue) {
      this.filteredProjects = this.projects;
    } else {
      searchValue = searchValue.toLowerCase();
      this.filteredProjects = this.projects.filter((project) =>
        project.name.toLowerCase().includes(searchValue)
      );
    }
  }
}
