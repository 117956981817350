import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, takeUntil } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { PageEvent } from '@angular/material/paginator';
import { PositionJobDetailsDialogComponent } from 'src/shared/components/dialog/customer-dialogs/position-job-detail/position-job-detail.component';
import { ClosePositionDialogComponent } from 'src/shared/components/dialog/customer-dialogs/close-position-dialog/close-position-dialog.component';
import { OpenPositionService } from '../../services/open-position.service';
import { ViewCustomerJobPopupComponent } from './view-job-detail/view-job-detail.component';
import { Sort } from '@angular/material/sort';

export interface PeriodicElement {
  name: string;
  email: string;
  notice: string;
  resume: string;
  application: string;
  status: string;
  action: string;
}

@Component({
  selector: 'app-position-detail',
  templateUrl: './position-job-details.component.html',
})
export class PositionJobDeatilsComponent {
  id!: string;
  candidateList: any[] = [];
  pageNumber: number = 0;
  limit: number = 25;
  search: string = '';
  sortBy: 'name' | 'modifiedAt' = 'modifiedAt';
  orderBy: 'ASC' | 'DESC' = 'DESC';
  form!: FormGroup;
  totalRecords: number = 0;
  lastSortBy: string = '';
  loading: boolean = false;
  element: any;

  @ViewChild('viewJobDetail') viewJobComponent!: ViewCustomerJobPopupComponent;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private openPositionService: OpenPositionService,
    private globalService: GlobalService,
    private formbuilder: FormBuilder
  ) {}

  openViewJobDrawer(): void {
    if (this.viewJobComponent) {
      this.viewJobComponent.isOpen = !this.viewJobComponent.isOpen;
    } else {
    }
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.getCandidateList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy
    );
    this.form = this.formbuilder.group({
      searchQuery: [''],
    });
    this.searchCandidate();
  }

  isStatusInterview(status: string): boolean {
    return (
      (!!status && status.toLowerCase() === 'interview') ||
      status.toLowerCase() === 'interviewed'
    );
  }

  isStatusSelected(status: string): boolean {
    return !!status && status.toLowerCase() === 'selected';
  }

  isStatusScreening(status: string): boolean {
    return !!status && status.toLowerCase() === 'screening';
  }

  isStatusRejected(status: string): boolean {
    return !!status && status.toLowerCase() === 'rejected';
  }

  getFormattedStatus(status: string): string {
    switch (status.toLowerCase()) {
      case 'screening':
        return 'Screening';
      case 'interview':
        return 'Interview';
      case 'selected':
        return 'Selected';
      case 'rejected':
        return 'Rejected';
      default:
        return '';
    }
  }

  displayedColumns: string[] = [
    'name',
    'email',
    'notice',
    'resume',
    'application',
    'status',
    'action',
  ];
  dataSource = new MatTableDataSource<PeriodicElement>([]);

  setElement(selectedElement: any): void {
    this.element = selectedElement;
    if (this.viewJobComponent) {
      this.viewJobComponent.id = selectedElement.id;
    }
  }

  onPageChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex;
    this.limit = event.pageSize;
    this.getCandidateList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy || 'ASC'
    );
  }

  getCandidateList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC'
  ): void {
    this.loading = true;
    this.openPositionService
      .getCandidateList(pageNumber, limit, search, sortBy, orderBy, this.id)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.candidateList = data?.data?.records;
          this.totalRecords = data?.data?.count || 0;
          this.loading = false;
          this.candidateList.sort((a, b) => {
            return (
              new Date(b.modifiedAt).getTime() -
              new Date(a.modifiedAt).getTime()
            );
          });
        },
        error: (error: any) => {
          console.error('Error in Candidate List request:', error);
          this.loading = false;
        },
      });
  }

  openEditDialog(element: any): void {
    const dialogRef = this.dialog.open(PositionJobDetailsDialogComponent, {
      height: 'auto',
      width: '467px',
      data: { isEditMode: true, element: element, positionID: this.id },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.getCandidateList(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy
        );
      }
    });
  }

  closePost(): void {
    const dialogRef = this.dialog.open(ClosePositionDialogComponent, {
      height: 'auto',
      width: '467px',
      data: {
        message: 'Are you sure you want to close this position?',
        isDeleteAction: true,
      },
    });

    dialogRef.componentInstance.canceled.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.componentInstance.confirmed.subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.openPositionService.closeOpenPosition(this.id).subscribe({
          next: () => {
            this.getCandidateList(
              this.pageNumber,
              this.limit,
              this.search,
              this.sortBy,
              this.orderBy
            );
            dialogRef.close();
            this.router.navigate(['customer/open-position']);
          },
          error: (error: any) => {
            console.error('Error deleting closing position:', error);
          },
        });
      }
    });
  }

  searchCandidate(): void {
    this.form
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        this.getCandidateList(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy
        );
      });
  }

  downloadResume(resumePath: string): void {
    if (!resumePath) {
      return;
    }

    this.openPositionService.getResume(resumePath).subscribe(
      (blob: Blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = this.extractFileName(resumePath);
        a.click();
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        console.error('Error downloading resume:', error);
      }
    );
  }

  private extractFileName(path: string): string {
    const parts = path.split('/');
    return parts[parts.length - 1];
  }

  getDefaultPhotoText(resourceName: string): string {
    if (!resourceName) return '';

    const firstChar = resourceName.charAt(0);
    const indexOfFirstSpace = resourceName.indexOf(' ');
    let secondChar = '';
    if (
      indexOfFirstSpace !== -1 &&
      indexOfFirstSpace < resourceName.length - 1
    ) {
      secondChar = resourceName.charAt(indexOfFirstSpace + 1);
    }

    return `${firstChar}${secondChar}`.toUpperCase();
  }

  onCandidateSort(event: Sort) {
    const sortBy = event.active;
    const orderBy = event.direction.toUpperCase() as 'ASC' | 'DESC';
    const validOrderBy = orderBy || 'ASC';

    if (sortBy !== this.lastSortBy) {
      this.pageNumber = 0;
    }
    this.lastSortBy = sortBy;
    this.openPositionService
      .getCandidateList(
        this.pageNumber,
        this.limit,
        this.search,
        sortBy,
        validOrderBy,
        this.id
      )
      .subscribe({
        next: (data: any) => {
          this.candidateList = data?.data?.records || [];
          this.dataSource.data = this.candidateList;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in Candidate List request:', error);
        },
      });
  }

  goBack() {
    this.router.navigate(['customer/open-position']);
  }
}
