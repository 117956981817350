import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SftButtonModule } from 'src/stories/components/button/button.module';
import { SidebarModule } from 'src/app/admin-portal/core/layouts/sidebar/sidebar.module';
import { CustomerOpenPositionComponent } from './components/open-position/open-position.component';
import { CustomerOpenPositionRoutingModule } from './open-position-routing.module';
import { CustomerLayoutModule } from '../../core/layout/layout.module';
import { AngularMaterialModule } from 'src/shared/modules/angular-material/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SftInputModule } from 'src/stories/components/Input/input.module';
import { RouterModule } from '@angular/router';
import { NgxEditorModule } from 'ngx-editor';
import { LoaderComponent } from 'src/shared/components/loader/loader.component';
import { JobPostComponent } from './components/job-post/job-post.component';
import { PositionJobDeatilsComponent } from './components/position-job-details/position-job-details.component';
import { ClosePositionDialogComponent } from 'src/shared/components/dialog/customer-dialogs/close-position-dialog/close-position-dialog.component';
import { ViewCustomerJobPopupComponent } from './components/position-job-details/view-job-detail/view-job-detail.component';
import { SharedModule } from '../../../../shared/modules/shared/shared.module';
import { FilterOpenPositionSidebarComponent } from './components/open-position/filter-sidebar/filter-open-position-sidebar.component';

@NgModule({
  declarations: [
    CustomerOpenPositionComponent,
    JobPostComponent,
    PositionJobDeatilsComponent,
    ClosePositionDialogComponent,
    ViewCustomerJobPopupComponent,
    FilterOpenPositionSidebarComponent,
  ],
  imports: [
    LoaderComponent,
    CustomerLayoutModule,
    CommonModule,
    SidebarModule,
    SftButtonModule,
    CustomerOpenPositionRoutingModule,
    RouterModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SftInputModule,
    NgxEditorModule,
    SharedModule,
  ],
})
export class CustomerOpenPositionModule {}
