<div class="main-content body-bg listing-wrapper custom-management-wrapper">
  <div class="p-6">
    <div class="d-flex justify-between align-center mb-6">
      <div>
        <h4 class="mat-headline-5 strong text" style="margin-bottom: 0">
          Open Position
        </h4>
        <p class="medium mat-body-1 text textXS">Explore Your Team Dynamics.</p>
      </div>
      <div class="d-flex">
        <mat-form-field
          appearance="outline"
          class="search-input"
          [formGroup]="form"
        >
          <input
            type="text"
            matInput
            placeholder="Search"
            formControlName="searchQuery"
          />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <div class="header-info-wrapper mr-3 ml-3">
          <button
            mat-button
            class="outline-button"
            (click)="openFilterDrawer()"
          >
          <img src="/images/filter.png" alt="" class="mr-2" />
          <div class="d-flex align-center">
          <span class="mr-2">Filter</span>
          <div *ngIf="isFilterApplied" class="active-circle"></div>
          </div>
          </button>
        </div>
        <sft-button
          [label]="'Create New Position'"
          [color]="'primary'"
          [variant]="'flat'"
          [disable]="false"
          [icon]="'home'"
          [disableRipple]="false"
          [size]="'xl'"
          (buttonClick)="createPosition()"
        ></sft-button>
      </div>
    </div>

    <div class="body-bg dashboard-wrapper">
      <div class="body-bg table-container customer-open-position">
        <app-loader *ngIf="loading"></app-loader>
        <div
          *ngIf="!loading && openPositionList.length === 0"
          class="required-star"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            height: calc(100vh - 200px);
            font-size: 20px;
          "
        >
          No Open Positions found!
        </div>

        <div
          class="overflow-auto cost-listing-wrapper"
          *ngIf="!loading && openPositionList.length > 0"
        >
          <table
            mat-table
            [dataSource]="openPositionList"
            class="custom-action-table"
            matSort
            (matSortChange)="onOpenPositionListSort($event)"
            style="cursor: pointer"
          >
            <ng-container matColumnDef="role">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="name"
                mat-sort-header
              >
                Role
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                [routerLink]="'position-job-details/' + element.op_positionID"
                title="{{ element.op_role || '--' }}"
              >
                <div class="d-flex align-center">
                  <span class="grey-text table-ellipse project-role-col">{{
                    element.op_role | truncate : 10 || "--"
                  }}</span>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="costCenter">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="cost-type"
                mat-sort-header="centerName"
              >
                Cost Center
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="grey-text table-ellipse project-role-col"
                title=" {{ element.cc_center_name || '--' }}"
                [routerLink]="'position-job-details/' + element.op_positionID"
              >
                {{
                  element?.cc_center_name
                    ? (element.cc_center_name | truncate : 10)
                    : "--"
                }}
              </td>
            </ng-container>
            <ng-container matColumnDef="projectName">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="role-type"
                mat-sort-header
              >
                Name
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="grey-text table-ellipse project-role-col"
                [routerLink]="'position-job-details/' + element.op_positionID"
                title="{{ element?.ac_name || '--' }}"
              >
              {{ element?.ac_name ? element.ac_name : "--" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="range">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="cost-type"
                mat-sort-header="experienceRange"
              >
                Range
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="grey-text cost-type-col"
                title="{{
                  getFormattedExperienceRange(element.op_experience_range)
                }}"
                [routerLink]="'position-job-details/' + element.op_positionID"
              >
                {{ getFormattedExperienceRange(element.op_experience_range) }}
              </td>
            </ng-container>
            <ng-container matColumnDef="startDate">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="date"
                mat-sort-header
              >
                Preferred Start Date
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="grey-text"
                [routerLink]="'position-job-details/' + element.op_positionID"
              >
                {{ element.op_start_date | date : "dd MMM, yyyy" || "--" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="openings">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="date"
                mat-sort-header="noOfResource"
              >
                No. of Openings
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="grey-text"
                [routerLink]="'position-job-details/' + element.op_positionID"
              >

              <span class="opening-col" title="{{ element.op_no_of_resource || '--' }}">  {{ element.op_no_of_resource || "--" }}</span>
               
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef class="action">Action</th>
              <td
                mat-cell
                *matCellDef="let element"
                class="text-center"
                style="cursor: pointer"
              >
                <mat-icon
                  [matMenuTriggerFor]="menuEdit"
                  (click)="setElement(element)"
                >
                  more_vert
                </mat-icon>
              </td>
              <mat-menu #menuEdit="matMenu" class="action-info">
                <button
                  *ngIf="element?.op_positionID"
                  mat-menu-item
                  (click)="onEditPosition(element.op_positionID)"
                >
                  Edit
                </button>
              </mat-menu>
            </ng-container>
            <tr
              mat-header-row
              class="action-table-header-data"
              *matHeaderRowDef="displayedColumns"
            ></tr>
            <tr
              mat-row
              class="action-table-body-data"
              *matRowDef="let row; columns: displayedColumns"
            ></tr>
          </table>
        </div>
        <mat-paginator
          *ngIf="!loading && openPositionList.length > 0"
          class="action-table-pagination w-full fixed bottom-0 customer-position"
          [length]="totalRecords"
          [pageSize]="limit"
          [pageIndex]="pageNumber"
          [pageSizeOptions]="[25, 50, 75, 100]"
          (page)="onPageChange($event)"
          aria-label="Select page"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
<app-open-position-dialog-filter
  #filter
  (filtersApplied)="handleFilterApplied($event)"
></app-open-position-dialog-filter>