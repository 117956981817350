<div
  class="main-content body-bg p-6 setting-wrapper"
  [formGroup]="positionForm"
>
  <div class="d-flex justify-between align-center mb-6">
    <div>
      <h4 class="mat-headline-5 strong text mb-1">
        {{ isEditMode ? "Edit Job Post" : "Create Job Post" }}
      </h4>
      <sft-button
        [label]="
          'Enter Job Details
        '
        "
        [color]="'primary'"
        className="grey-text-btn textXS p-0"
        [variant]="'default'"
        [disable]="false"
        [withIcon]="true"
        [icon]="'keyboard_backspace'"
        [disableRipple]="false"
        [size]="'small'"
        (click)="openPositions()"
      ></sft-button>
    </div>

    <sft-button
      [style.cursor]="isButtonDisabled ? 'not-allowed' : 'pointer'"
      [label]="isEditMode ? 'Save Post' : 'Publish Post'"
      [color]="'primary'"
      [variant]="'flat'"
      [icon]="'home'"
      [size]="'xl'"
      [disable]="isButtonDisabled"
      (click)="isButtonDisabled ? null : onSubmit()"
    ></sft-button>
  </div>
  <div class="d-flex justify-center">
    <div class="account-detail-tab settings hide-scroll w-full">
      <div class="p-6">
        <div class="row">
          <div class="col s12 m6 l6 mb-4">
            <div class="input-wrapper">
              <p class="mat-body-1 text mb-1 input-label">
                Role <span class="required-star">*</span>
              </p>
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <input
                  matInput
                  required
                  formControlName="role"
                  placeholder="Enter here"
                />
              </mat-form-field>
              <div
                *ngIf="
                  positionForm.get('role')?.touched ||
                  positionForm.get('role')?.dirty
                "
              >
                <span
                  class="required-star"
                  *ngIf="positionForm.get('role')?.hasError('required')"
                >
                  Field is required.
                </span>
                <div
                  class="required-star mt-1"
                  *ngIf="
                    !positionForm.get('role')?.hasError('required') &&
                    positionForm.get('role')?.hasError('whitespace')
                  "
                >
                  Field cannot be empty or contain only whitespace.
                </div>
              </div>
            </div>
          </div>
          <div class="col s12 m6 l6">
            <div class="input-wrapper mb-4">
              <p class="mat-body-1 text mb-1 font-weight-500 input-label">
                Project Name
              </p>
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <mat-select
                  placeholder="Select"
                  formControlName="projectId"
                  panelClass="projectfield"
                >
                  <mat-option
                    *ngFor="let project of projectList"
                    [value]="project.projectId"
                    class="project-field"
                    >{{ project.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <span
                class="required-star"
                *ngIf="
                  positionForm.get('projectId')?.invalid &&
                  (positionForm.get('projectId')?.touched ||
                    positionForm.get('projectId')?.dirty)
                "
                >Field is required.</span
              >
            </div>
          </div>
        </div>
        <div>
          <div class="row">
            <div class="col s12 m6 l6">
              <div class="input-wrapper mb-4">
                <p class="mat-body-1 text mb-1 font-weight-500 input-label">
                  Cost Center <span class="required-star">*</span>
                </p>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <mat-select
                    placeholder="Select"
                    formControlName="cost_centerID"
                    required
                  >
                    <mat-option
                      *ngFor="let cost of costList"
                      [value]="cost.cc_cost_centerID"
                      >{{ cost.cc_center_name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <span
                  class="required-star"
                  *ngIf="
                    positionForm.get('cost_centerID')?.invalid &&
                    (positionForm.get('cost_centerID')?.touched ||
                      positionForm.get('cost_centerID')?.dirty)
                  "
                  >Field is required.</span
                >
              </div>
            </div>
            <div class="col s12 m6 l6">
              <div class="input-wrapper mb-4">
                <p class="mat-body-1 text mb-1 font-weight-500 input-label">
                  Experience Range <span class="required-star">*</span>
                </p>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <mat-select
                    placeholder="Select"
                    formControlName="experienceRange"
                    required
                  >
                    <mat-option
                      *ngFor="let range of experienceRange"
                      [value]="range"
                      >{{ range }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <span
                  class="required-star"
                  *ngIf="
                    positionForm.get('experienceRange')?.invalid &&
                    (positionForm.get('experienceRange')?.touched ||
                      positionForm.get('experienceRange')?.dirty)
                  "
                  >Field is required.</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <div class="row">
            <div class="col s12 m6 l6">
              <div class="input-wrapper">
                <p class="mat-body-1 text mb-1 input-label">
                  Preferred Start Date <span class="required-star">*</span>
                </p>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input
                    matInput
                    [matDatepicker]="startpicker"
                    placeholder="Select"
                    formControlName="startDate"
                    required
                  />
                  <mat-datepicker-toggle
                    matIconSuffix
                    [for]="startpicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #startpicker></mat-datepicker>
                </mat-form-field>
                <span
                  class="required-star"
                  *ngIf="
                    positionForm.get('startDate')?.invalid &&
                    (positionForm.get('startDate')?.touched ||
                      positionForm.get('startDate')?.dirty)
                  "
                  >Field is required.</span
                >
              </div>
            </div>
            <div class="col s12 m6 l6">
              <div class="input-wrapper">
                <p class="mat-body-1 text mb-1 input-label">
                  No. of Resources <span class="required-star">*</span>
                </p>
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                  <input
                    appNumericInput
                    maxlength="3"
                    matInput
                    required
                    formControlName="noOfResource"
                    placeholder="Enter"
                    (numericInputValidityChange)="
                      handleNumericInputValidityChange($event)
                    "
                  />
                </mat-form-field>
                <span class="required-star" *ngIf="!isNumericInputValid"
                  >Please enter a numeric value.</span
                >
                <span
                  class="required-star"
                  *ngIf="
                    positionForm.get('noOfResource')?.invalid &&
                    (positionForm.get('noOfResource')?.touched ||
                      positionForm.get('noOfResource')?.dirty) &&
                    isNumericInputValid
                  "
                >
                  Field is required.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="input-wrapper mb-4">
          <p class="mat-body-1 text mb-1 font-weight-500 input-label">
            Job Description <span class="required-star">*</span>
          </p>
          <div class="editor">
            <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
            </ngx-editor-menu>
            <ngx-editor
              [editor]="editor"
              formControlName="jobDescription"
              required
            ></ngx-editor>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
