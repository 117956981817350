import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerOpenPositionComponent } from './components/open-position/open-position.component';
import { PositionJobDeatilsComponent } from './components/position-job-details/position-job-details.component';
import { JobPostComponent } from './components/job-post/job-post.component';

const routes: Routes = [
  {
    path: '',
    component: CustomerOpenPositionComponent,
  },
  {
    path: 'position-job-details/:id',
    component: PositionJobDeatilsComponent,
  },
  {
    path: 'create-post',
    component: JobPostComponent,
  },
  {
    path: 'edit-post/:id',
    component: JobPostComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerOpenPositionRoutingModule {}
